export const realtorData = {
  email: 'monteropropiedades@unne.cl',
  phone:'+56999989923'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'monteropropiedades@unne.cl',
  phone:'999989923',
  wsp:'999989923',
}
